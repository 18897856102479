import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    // name: "index",
    // component: () => import("../views/home/index.vue"),
    redirect: "/management",
  },
  {
    path: "/management",
    name: "management",
    component: () => import("../views/management/index.vue"),
  },
  {
    path: "/operation",
    name: "operation",
    component: () => import("../views/operation/index.vue"),
  },
  {
    path: "/production",
    name: "production",
    component: () => import("../views/production/index.vue"),
  },
  {
    path: "/planning",
    name: "planning",
    component: () => import("../views/planning/index.vue"),
  },
  // login
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/index.vue"),
  },
];
const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  // 检查除了 /login 之外的路由
  if (to.path !== "/login") {
    const token = localStorage.getItem("token");

    // 如果没有 token，重定向到 /login
    if (!token) {
      next("/login");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
